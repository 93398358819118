import React, { Component, useEffect, useState } from "react";
import DailyMenu from "../../assets/components/DailyMenu/DailyMenu";
import Profil from "../Profil/Profil";
import QrCode from "../../assets/components/QRCode/QrCode";
import QrCodeTicket from "../../assets/QRCodeTicket/QRCodeTicket";
import Personnel from "../Personnel/Personnel";

import {
  Image,
  Col,
  Row,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "./HomePage.css";

function Tuto() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="buttonTuto" onClick={handleShow}>
        Etapes obligatoires{" "}
        <Button onClick={handleShow} className="flecheTuto">
          {">"}
        </Button>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Comment activer votre compte ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="title1">
            Veillez à avoir effectuer les actions suivantes afin de permettre à
            vos clients de donner des pourboires
          </p>
          <p className="para1">
            Définissez les paramètres de redistribution : collectif ou
            individuel
            <br />
            Ajoutez vos bénéficiaires et/ou désignez votre référent selon la
            distribution choisie
            <br />
            <br />
            Demandez à chaque personne de votre équipe de valider sa création de
            compte à réception de votre mail
            <br />
            <br />
            En cas de non réception, pensez à leur demander de vérifier les
            courriers indésirables.
            <br /> <br />
            Le bénéficiaire peut aussi directement créer un compte et être
            rattaché par la suite à votre établissement. (notamment si cette
            personne travaille dans plusieurs établissements utilisant
            Tipourboire.)
            <br />
          </p>

          <p className="title1">
            Et pensez à mettre en avant votre QR Code pour booster vos
            pourboires !
          </p>
          <p className="para1">
            Imprimez et mettez en évidence votre QR Code pour permettre au plus
            grand nombre de le flasher
            <br />
            sur des présentoirs dans votre établissement
            <br /> Sur le comptoir
            <br />
            A côté de votre caisse
            <br />
            Sur votre TPE
            <br />
            Sur votre ticket de caisse
            <br />
            Ou encore dans vos coupelles ou sur vos cartes
            <br />
            tous les supports sont bons
            <br />
            pour capter l'attention !
            <br />
            <br />
            ET avec QR carte
            <br />
            Mettez à disposition votre carte après l'avoir simplement prise en
            photo
            <br />
            et chargée dans votre espace :
            <br />
            simple et efficace !
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modalButton"
            variant="secondary"
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantName: "",
      isLoading: false,
      data: {},
      pourboireGeneral: "",
      tabServeur: [],
      show: false,
      show2: false,
      show3: false,
      email: "",
      menu: {},
      viewCode: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal = () => {
    this.setState({ show: false });
    console.log("this", this.state.show);
  };

  isReferent = () => {
    console.log(
      "test",
      this.state.pourboireGeneral === true,
      this.state.tabServeur.length === 0
    );
    if (
      this.state.pourboireGeneral === true &&
      this.state.tabServeur.length === 0
    ) {
      this.setState({ show: true });
    } else {
      return null;
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addAffiliation = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email,
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + localStorage.getItem("token"),
    });

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
    };

    fetch(
      "https://back-end.osc-fr1.scalingo.io/restaurateur/management/affiliation",
      options
    )
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        localStorage.setItem("affi", "ok");
        this.hideModal();
        if (responseData === true) {
          console.log("hellooooooooooooooo");

          alert("Votre demande a bien été prise en compte");
        } else {
          alert(responseData.messageAffi);
        }
      });
  };

  //lorsqu'il n'y a pas de référent
  modalReferent = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={() => {
          this.setState({ show: false });
        }}
        animation={true}
        backdrop={true}
        keyboard={false}
        style={{ overlay: { zIndex: 3 } }}>
        <Modal.Header closeButton>
          <Modal.Title>Désigner votre référent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="affiPop">
            <Col s={12} md={12}>
              <p>
                Saisissez ci-dessous le mail de la personne <br />
                qui sera en charge de répartir le pot selon les modalités
                choisies
              </p>
            </Col>
            <Col>
              <input
                type="text"
                name="email"
                onChange={this.handleInput}
                placeholder="Bénéficiaire"
                className="inputAffi"
              />
              <input
                type="submit"
                value="Envoyer la demande"
                onClick={this.addAffiliation}
                className="button"
              />
            </Col>
            <Col s={12} md={12}>
              <p>
                À réception du mail, le référent pourra commencer à répartir.
              </p>
            </Col>
            <Col s={12} md={12}>
              <p>
                <strong>
                  Cette pop up s'affichera tant que votre référant n'a pas
                  accepté l'affiliation.
                </strong>
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  //Modal si plus de référent et tips dans communTips

  modalTips = () => {
    return (
      <Modal
        show={this.state.show3}
        onHide={() => {
          this.setState({ show3: false });
        }}
        animation={true}
        backdrop={true}
        keyboard={false}
        style={{ overlay: { zIndex: 3 } }}>
        <Modal.Header closeButton>
          <Modal.Title>Vous avez de l'argent dans votre pot commun</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="affiPop">
            <Col s={12} md={12}>
              <p>
                Il vous reste des pourboires dans votre pot commun mais vous
                n'avez aucun référent pour le distribuer. Désignez un référent
                dés maintenant pour pouvoir bénéficier de vos pourboires.
              </p>
            </Col>
            <Col>
              <input
                type="text"
                name="email"
                onChange={this.handleInput}
                placeholder="Bénéficiaire"
                className="inputAffi"
              />
              <input
                type="submit"
                value="Envoyer la demande"
                onClick={this.addAffiliation}
                className="button"
              />
            </Col>
            <Col s={12} md={12}>
              <p>
                À réception du mail, le référent pourra commencer à répartir.
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  // lorsque le mail est envoyé mais pas validé par le benef
  /* modalNearlyReferent = () => {
    return (
      <Modal
        show={this.state.show2}
        onHide={false}
        animation={true}
        backdrop={true}
        keyboard={false}
        style={{ overlay: { zIndex: 3 } }}>
        <Modal.Header>
          <Modal.Title>Désigner votre référent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="affiPop">
            <Col s={12} md={12}>
              <p>
                Le référent que vous avez désigné pour répartir le pot commun
                n'a pas encore accepté votre affiliation référent.
              </p>
            </Col>

            <Col s={12} md={12}>
              <p>
                Demandez lui de consulter ses mails pour accepter l'affiliation
                référent sinon les pourboires ne pourront pas être redistribués.
              </p>
            </Col>
            <Col>
              <input
                type="submit"
                value="J'ai compris"
                onClick={() => {
                  this.setState({ show2: false });
                }}
                className="button"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };*/

  handleAfterPrint = () => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  };

  handleBeforePrint = () => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  };

  handleOnBeforeGetContent = () => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    this.setState({ text: "Loading new text...", isLoading: true });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          { text: "New, Updated Text!", isLoading: false },
          resolve
        );
      }, 2000);
    });
  };

  reactToPrintTrigger = () => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button>Print </button>;
  };

  getRestaurantName = () => {
    const headers = new Headers({
      Authorization: "Bearer " + localStorage.getItem("token"),

      "X-Requested-With": "XMLHttpRequest",
    });

    const options = {
      method: "GET",
      headers: headers,
    };

    fetch("https://back-end.osc-fr1.scalingo.io/restaurateur/profil", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (responseObject) => {
          console.log("ResponseObject", responseObject);
          localStorage.setItem(
            "propsRestaurant",
            JSON.stringify(responseObject.restaurantName)
          );
          this.setState({
            restaurantName: responseObject.restaurantName,
          });
          this.setState({ pourboireGeneral: responseObject.pourboireGeneral });
          this.setState({ tabServeur: responseObject.tabServeur });
          this.setState({ menu: responseObject?.menu?.dailyMenu?.picture });
          this.setState({ data: JSON.stringify(responseObject) });

          if (!this.state.menu) {
            this.setState({ viewCode: false });
          } else {
            this.setState({ viewCode: true });
          }

          //condition pour afficher popup référent si pas de référent en pourboireGénéral
          if (
            (responseObject.pourboireGeneral === true &&
              responseObject.tabServeur.length === 0) ||
            !responseObject.referent ||
            responseObject.referent.email == ""
          ) {
            this.setState({ show: !this.state.show });
          } else {
            this.setState({ show: false });
          }

          if (
            localStorage.getItem("affi") &&
            responseObject.tabServeur.length === 0
          ) {
            //this.setState({ show: false });
            this.setState({ show2: true });
            console.log("show2", this.state.show2);
          }

          if (responseObject.referent?.email === "") {
            const headers = new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),

              "X-Requested-With": "XMLHttpRequest",
            });

            const options = {
              method: "GET",
              headers: headers,
            };

            fetch(
              "https://back-end.osc-fr1.scalingo.io/restaurateur/getWallet",
              options
            )
              .then((response) => {
                return response.json();
              })
              .then((responseObject) => {
                console.log("response", responseObject);
                if (responseObject > 0) {
                  this.setState({ show3: true });
                }
              });
          }
        },

        (error) => {
          console.log(error);
        }
      );
  };

  // renderButtonSub = () => {
  //   if (this.state.abonne === true) {
  //     return (
  //       <div>
  //         <button
  //           className="buttonPremium"
  //           variant="primary"
  //           onClick={() => {
  //             this.props.history.push("/dataClient");
  //           }}>
  //           Accès à mon espace premium <br />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           className="buttonPremium"
  //           variant="primary"
  //           onClick={() => {
  //             this.props.history.push("/abonnement");
  //           }}>
  //           Souscrire à l'abonnement premium <br />
  //         </button>
  //       </div>
  //     );
  //   }
  // };

  componentDidMount() {
    this.getRestaurantName();
  }

  render() {
    return (
      <div className="homepage">
        <Row className="rowGlobal">
          <Col md={10}>
            <Personnel className="personnel" />

            <h1 className="titleQR">Mes QR Codes</h1>

            <Row className="rowGlobal">
              <Col md={{ span: 9, offset: 7 }} className="colTuto">
                {this.modalReferent()}
                {this.modalTips()}
                <Tuto />
              </Col>
              <Row className="rowGlobal">
                <Col xs={9} s={9} md={6}>
                  <p className="titleQR">
                    <strong> QR CODE Pourboire de votre établissement</strong>
                  </p>
                  <p className="qrSub">
                    À télécharger pour impression sur vos supports ou insertion
                    dans votre logiciel de caisse ou d'encaissement
                  </p>
                  <div>
                    <QrCodeTicket />;
                  </div>
                </Col>

                <Col xs={9} s={9} md={6}>
                  <p className="titleQR">
                    {" "}
                    <strong>QR CODE Carte</strong>{" "}
                  </p>
                  <p className="qrSub">
                    À télécharger pour impression
                    <br />
                    et mise à disposition au sein de votre établissement
                  </p>
                  {this.state.viewCode === false ? (
                    <p className="qrSub">
                      Veuillez télécharger une carte pour utiliser votre QRcode
                      carte
                    </p>
                  ) : (
                    <QrCode />
                  )}
                </Col>
              </Row>
            </Row>
          </Col>
          <Row className="rowGlobal marginToop">
            <Col className="centerCol" md={3}>
              <DailyMenu className="menuhome" />
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}

export default HomePage;
